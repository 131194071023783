@charset "UTF-8";
/* ================================================================================
値の定義
================================================================================ */
@import url("https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700&display=swap");
/* ================================================================================
importファイル
================================================================================ */
/* ================================================================================
VW設定
================================================================================ */
/*PC設定*/
/*tab設定*/
/*SP設定*/
/*フォントサイズ*/
/*余白計算*/
/*余白計算（一括指定）*/
/*Placeholder*/
/* EXTEND------------------------------------- */
/* ===================================
リセット
=================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #605C6E;
  font-size: 62.5%;
  font-family: "Zen Kaku Gothic New", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 100%;
  }
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #999;
  padding: .35em .625em .75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  color: #000;
  outline: none;
}

a:visited {
  color: #000;
  outline: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #999;
}

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}

label {
  font-weight: normal;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  padding: 0;
}

input::-ms-clear, input::-ms-reveal {
  visibility: hidden;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
}

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* =========================================
印刷時の定義
========================================== */
＠media print section {
  display: block;
  page-break-before: always;
}

＠media print pre,
＠media print code {
  page-break-after: always;
}

@page {
  ＠media print {
    margin-top: .4cm;
    margin: .5cm;
  }
}

＠media print p,
＠media print a,
＠media print code,
＠media print pre {
  word-wrap: break-word;
}

＠media print pre a[href]:after {
  content: "";
}

＠media print pre abbr[title]:after {
  content: "";
}

＠media print pre .ir a:after,
＠media print pre a[href^='javascript:']:after,
＠media print pre a[href^='#']:after {
  content: '';
}

＠media print .noPrint {
  display: none !important;
}

＠media print body {
  width: 1280px;
  font-size: 12pt;
  transform: scale(0.8);
  transform-origin: 0 0;
  -webkit-print-color-adjust: exact;
}

＠media print body .continer {
  padding-top: 0;
}

/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0;
}

@media screen and (max-width: 767px) {
  #responsibleCheck {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
@media screen and (max-width: 767px) {
  .pcOnly {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .spOnly {
    display: block !important;
  }
}

html {
  font-size: 62.5%;
}

body {
  position: relative;
  font-family: "Zen Kaku Gothic New", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  color: #555;
  background: #FFFCF4;
  overflow: hidden;
}

body.fixedBody {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
}

/* ハイトカラー消去
------------------------------------------------------------- */
.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
}

main article {
  width: 100%;
  height: 100%;
}

main article section {
  display: block;
  width: 100%;
  position: relative;
}

.maxContainer {
  max-width: 1600px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .maxContainer {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .maxContainer {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .maxContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .maxContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .maxContainer {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .maxContainer {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .maxContainer {
    padding-left: 2.92826vw;
  }
}

.container {
  max-width: 1520px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .container {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .container {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .container {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .container {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .container {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .container {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .container {
    padding-left: 2.92826vw;
  }
}

.minContainer {
  max-width: 1406px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .minContainer {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .minContainer {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .minContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .minContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .minContainer {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .minContainer {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .minContainer {
    padding-left: 2.92826vw;
  }
}

a {
  color: #555;
}

/* 共通デザイン
------------------------------------------------------------- */
/* アンカーリンクデザイン
------------------------------------------------------------- */
/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* パン屑リスト
------------------------------------------------------------- */
.breadcrumb {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 2.08333vw;
  padding-bottom: 1.04167vw;
  clear: both;
}

@media screen and (min-width: 1367px) {
  .breadcrumb {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-top: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-top: 2.92826vw;
  }
}

@media screen and (min-width: 1367px) {
  .breadcrumb {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb {
    padding-top: 4vw;
    padding-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-bottom: 3.66032vw;
  }
}

.breadcrumb ol {
  position: relative;
  width: 100%;
  word-break: break-all;
  text-align: left;
  line-height: 1;
  color: #acabab;
  margin: 0 auto;
}

.breadcrumb ol::after {
  display: table;
  clear: both;
  content: "";
}

@media screen and (max-width: 767px) {
  .breadcrumb ol {
    max-width: initial;
  }
}

.breadcrumb ol > li {
  display: inline;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.9px;
  color: #666;
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2.66667vw;
  }
}

.breadcrumb ol > li a {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.9px;
  color: #666;
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li a {
    font-size: 1.31772vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li a {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li a {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li a {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2.66667vw;
  }
}

.breadcrumb ol > li:first-child a {
  text-decoration: none;
}

.breadcrumb ol > li + li::before {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  padding-right: 0.52083vw;
  padding-left: 0.41667vw;
  color: #444444;
  content: "＞";
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    padding-left: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.58565vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.58565vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 2rem;
    font-size: 1.04167vw;
    padding-right: 1.33333vw;
    padding-left: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1367px) {
  .breadcrumb ol > li + li::before {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.73206vw;
  }
}

/* =====================================
ヘッダー
===================================== */
#header {
  margin-top: 5.20833vw;
  margin-bottom: 5.20833vw;
  padding-top: 5.20833vw;
  padding-bottom: 5.20833vw;
  width: 5.20833vw;
  max-width: 100px;
  font-size: 1.6rem;
  font-size: 0.83333vw;
}

@media screen and (min-width: 1367px) {
  #header {
    margin-top: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header {
    margin-top: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header {
    margin-top: 7.32064vw;
  }
}

@media screen and (min-width: 1367px) {
  #header {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 1367px) {
  #header {
    padding-top: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header {
    padding-top: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header {
    padding-top: 7.32064vw;
  }
}

@media screen and (min-width: 1367px) {
  #header {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 1367px) {
  #header {
    width: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header {
    width: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header {
    width: 7.32064vw;
  }
}

@media screen and (min-width: 1367px) {
  #header {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #header {
    font-size: 2.13333vw;
  }
}

/* =====================================================
フッター
===================================================== */
/* 見出し装飾--------------------------- */
/* アニメーション関連---------------------- */
.fadeIn {
  opacity: 0;
  visibility: hidden;
  transform: translateY(100px);
  transition: opacity 1s .5s, transform 1s .5s;
  backface-visibility: hidden;
}

.fadeIn.active {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.fadeIn.active .fadeIn_second {
  opacity: 1;
  visibility: visible;
}

.fadeIn.active .fadeIn_third {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.fadeIn .fadeIn_second {
  opacity: 0;
  visibility: hidden;
  transition: opacity .8s 1s;
}

.fadeIn .fadeIn_third {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: opacity .8s .7s, transform .8s .7s;
}

@keyframes animeImgCircle {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  95% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

/* ボタン装飾------------------------------ */
button {
  outline: none;
}

.columnLayout {
  display: flex;
  justify-content: space-between;
}

.columnLayout .col1 {
  flex-basis: 100%;
}

.columnLayout .col2 {
  flex-basis: 50%;
}

.columnLayout .col3 {
  flex-basis: 33%;
}

.columnLayout .col4 {
  flex-basis: 25%;
}

.columnLayout .col5 {
  flex-basis: 20%;
}

.columnLayout .col6 {
  flex-basis: 16%;
}

.columnLayout .col7 {
  flex-basis: 14%;
}

.columnLayout .col8 {
  flex-basis: 12%;
}

#loading {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  background: #ffffff;
  opacity: 1;
  z-index: 100000;
}

.hoverAnime img {
  transition: transform .3s;
}

.hoverAnime:hover {
  cursor: pointer;
}

.hoverAnime:hover img {
  transform: scale(1.2);
}

.mainContentBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
  opacity: 0;
  transition: opacity 1s;
}

.mainContentBox.isShow {
  opacity: 1;
}

.mainContentBox .introTxtArea, .mainContentBox .startArea, .mainContentBox .choiceArea {
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s;
}

.mainContentBox .introTxtArea.isIn, .mainContentBox .startArea.isIn, .mainContentBox .choiceArea.isIn {
  visibility: visible;
}

.mainContentBox .introTxtArea.isShow, .mainContentBox .startArea.isShow, .mainContentBox .choiceArea.isShow {
  opacity: 1;
}

.mainContentBox .introTxtArea.hide, .mainContentBox .startArea.hide, .mainContentBox .choiceArea.hide {
  opacity: 0;
  display: none !important;
}

/*-----------------------------------
    背景のスタイル
    -----------------------------------*/
.bgContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s;
}

.bgContent.isShow {
  opacity: 1;
}

.bgContent .cornerImg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.bgContent .cornerImg .corner {
  width: 8.48%;
  max-width: 163px;
}

@media screen and (max-width: 767px) {
  .bgContent .cornerImg .corner {
    width: 9.581%;
    max-width: 75px;
  }
}

.bgContent .cornerImg .corner.corner01 {
  position: absolute;
  left: 4.166%;
  top: 4.629%;
}

@media screen and (max-width: 767px) {
  .bgContent .cornerImg .corner.corner01 {
    left: 5.333%;
    top: 2.717%;
  }
}

.bgContent .cornerImg .corner.corner02 {
  position: absolute;
  right: 4.166%;
  top: 4.629%;
}

@media screen and (max-width: 767px) {
  .bgContent .cornerImg .corner.corner02 {
    right: 5.333%;
    top: 2.717%;
  }
}

.bgContent .cornerImg .corner.corner03 {
  position: absolute;
  right: 4.166%;
  bottom: 4.629%;
}

@media screen and (max-width: 767px) {
  .bgContent .cornerImg .corner.corner03 {
    right: 5.333%;
    bottom: 2.717%;
  }
}

.bgContent .cornerImg .corner.corner04 {
  position: absolute;
  left: 4.166%;
  bottom: 4.629%;
}

@media screen and (max-width: 767px) {
  .bgContent .cornerImg .corner.corner04 {
    left: 5.333%;
    bottom: 2.717%;
  }
}

.bgContent .cornerImg .side {
  height: 47.96%;
  width: 1.23%;
  position: absolute;
}

.bgContent .cornerImg .side.side01 {
  left: 4.166%;
  top: 50%;
  transform: translate(0, -50%);
}

.bgContent .cornerImg .side.side02 {
  right: 4.166%;
  top: 50%;
  transform: translate(0, -50%);
}

@media screen and (max-width: 767px) {
  .bgContent .cornerImg .side {
    width: 54.4%;
    height: 2.397%;
  }
  .bgContent .cornerImg .side.side03 {
    top: 2.717%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .bgContent .cornerImg .side.side04 {
    bottom: 2.717%;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.bgContent .cornerImg .cornerSolid {
  width: 8.802%;
  height: 8.611%;
  position: absolute;
}

.bgContent .cornerImg .cornerSolid.cornerSolid01 {
  top: 13.88%;
  left: 13.02%;
}

.bgContent .cornerImg .cornerSolid.cornerSolid02 {
  top: 13.88%;
  right: 13.02%;
}

.bgContent .cornerImg .cornerSolid.cornerSolid03 {
  bottom: 13.88%;
  right: 13.02%;
}

.bgContent .cornerImg .cornerSolid.cornerSolid04 {
  bottom: 13.88%;
  left: 13.02%;
}

.bgContent .cloudImg {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: opacity 1s;
}

.bgContent .cloudImg.hide {
  opacity: 0;
}

.bgContent .cloudImg .cloud {
  opacity: 0;
}

.bgContent .cloudImg .cloud01 {
  position: absolute;
  width: 21.82%;
  height: 11.2%;
  top: 30.18%;
  left: 11.56%;
}

@media screen and (max-width: 767px) {
  .bgContent .cloudImg .cloud01 {
    width: 46.273%;
    height: 9.057%;
    top: 13.134%;
    left: 0;
  }
}

.bgContent .cloudImg .cloud02 {
  position: absolute;
  width: 20.36%;
  height: 9.81%;
  top: 46.66%;
  left: .46%;
}

.bgContent .cloudImg .cloud03 {
  position: absolute;
  width: 14.42%;
  height: 9.72%;
  bottom: 29.25%;
  left: 14.11%;
}

@media screen and (max-width: 767px) {
  .bgContent .cloudImg .cloud03 {
    width: 36.056%;
    height: 9.355%;
    bottom: 15.579%;
    left: 0;
  }
}

.bgContent .cloudImg .cloud04 {
  position: absolute;
  width: 23.95%;
  height: 11.2%;
  top: 31.75%;
  right: 4.375%;
}

.bgContent .cloudImg .cloud05 {
  position: absolute;
  width: 18.07%;
  height: 15.74%;
  bottom: 35.5%;
  right: 10.67%;
}

@media screen and (max-width: 767px) {
  .bgContent .cloudImg .cloud05 {
    width: 35.466%;
    height: 11.775%;
    bottom: 25.905%;
    right: 0;
  }
}

.paperAnime {
  position: fixed;
  z-index: 100000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  transition: all .3s;
}

@media screen and (max-width: 767px) {
  .paperAnime {
    width: 1920px;
    height: 1080px;
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

.paperAnime svg {
  width: 100%;
  height: 100%;
}

.paperAnime svg .st0 {
  fill: #EDE1CA !important;
  stroke: #EDE1CA !important;
  will-change: transform, opacity;
  shape-rendering: optimizeQuality;
}

.paperAnime.isShow {
  visibility: visible;
  opacity: 1;
}

/************************************
トップページ
***********************************/
/* ==============================================
トップページ
============================================== */
#indexTop .mainArea {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  /*-----------------------------------
  introArea
  -----------------------------------*/
  /*-----------------------------------
  introTxtArea
  -----------------------------------*/
  /*-----------------------------------
  startArea
  -----------------------------------*/
  /*-----------------------------------
  choiceArea
  -----------------------------------*/
}

#indexTop .mainArea .introArea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s;
}

#indexTop .mainArea .introArea.hide {
  opacity: 0;
}

#indexTop .mainArea .introArea .kichiPartsBox {
  position: relative;
  width: 100%;
  height: 100%;
}

#indexTop .mainArea .introArea .kichiPartsBox img {
  position: absolute;
  top: 0;
  opacity: 0;
}

#indexTop .mainArea .introArea .kichiPartsBox img.parts01 {
  left: 0;
  right: 0;
  margin: 0 auto;
  animation: kichiAnime linear 20s 0s infinite;
  width: 91%;
}

#indexTop .mainArea .introArea .kichiPartsBox img.parts02 {
  left: 3%;
  right: 0;
  margin: 0 auto;
  animation: kichiAnime linear 16s 0s infinite;
  width: 94%;
}

#indexTop .mainArea .introArea .kichiPartsBox img.parts03 {
  left: 0;
  right: 0;
  margin: 0 auto;
  animation: kichiAnime linear 30s 3s infinite;
  width: 94%;
}

#indexTop .mainArea .introTxtArea {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .introTxtArea {
    align-items: flex-start;
  }
}

#indexTop .mainArea .introTxtArea .introTxtAreaInner {
  width: 81.203%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  position: relative;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .introTxtArea .introTxtAreaInner {
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
  }
}

#indexTop .mainArea .introTxtArea .introTxtAreaInner:before {
  content: "";
  background: url(/images/index/index_intro_right_icon.svg) bottom center no-repeat;
  background-size: contain;
  width: 15.25%;
  height: 100%;
  display: inline-block;
  margin-left: auto;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .introTxtArea .introTxtAreaInner:before {
    content: none;
  }
}

#indexTop .mainArea .introTxtArea .introTxtAreaInner:after {
  content: "";
  background: url(/images/index/index_intro_left_icon.svg) bottom center no-repeat;
  background-size: contain;
  width: 15.25%;
  height: 100%;
  display: inline-block;
  margin-right: auto;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .introTxtArea .introTxtAreaInner:after {
    background: url(/images/index/index_intro_center_icon.svg) bottom center no-repeat;
    background-size: contain;
    width: 100%;
    height: 33.526%;
    margin: auto 0 0;
  }
}

#indexTop .mainArea .introTxtArea .introTxtAreaInner .siteTtl {
  letter-spacing: .075em;
  writing-mode: vertical-rl;
  width: 1em;
  line-height: 1;
}

#indexTop .mainArea .introTxtArea .introTxtAreaInner .siteDesc {
  line-height: 1.8;
  letter-spacing: .075em;
  margin-right: 2em;
  writing-mode: vertical-rl;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .introTxtArea .introTxtAreaInner .siteDesc {
    line-height: 1.7;
    width: 13.6em;
  }
}

#indexTop .mainArea .introTxtArea .introTxtAreaInner .siteDesc span {
  writing-mode: horizontal-tb;
  font-family: "Zen Kaku Gothic New", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  line-height: 1.4;
  letter-spacing: .075em;
  font-weight: 500;
}

#indexTop .mainArea .startArea {
  padding: 13.888% 9.2592% 8.055%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .startArea {
    padding: 0 5.3333%;
  }
}

#indexTop .mainArea .startArea .centerMain {
  height: 72.632%;
  position: relative;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .startArea .centerMain {
    height: 67.514%;
  }
}

#indexTop .mainArea .startArea .centerMain .rightTxt {
  position: absolute;
  right: 0;
  bottom: 0;
  writing-mode: vertical-rl;
  height: 77.533%;
}

@media screen and (min-width: 768px) {
  #indexTop .mainArea .startArea .centerMain .rightTxt {
    display: flex;
    justify-content: center;
  }
}

#indexTop .mainArea .startArea .centerMain .leftTxt {
  position: absolute;
  left: 0;
  bottom: 0;
  writing-mode: vertical-rl;
  height: 77.533%;
}

@media screen and (min-width: 768px) {
  #indexTop .mainArea .startArea .centerMain .leftTxt {
    display: flex;
    justify-content: center;
  }
}

#indexTop .mainArea .startArea .centerMain .leftTxt .leftTxtNum {
  writing-mode: horizontal-tb;
}

#indexTop .mainArea .startArea .centerMain .centerMainImg {
  height: 100%;
  width: 79.834%;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .startArea .centerMain .centerMainImg {
    width: 100%;
  }
}

#indexTop .mainArea .startArea .centerMain .centerMainImg .animeImgArea {
  position: relative;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: center;
  z-index: 0;
}

#indexTop .mainArea .startArea .centerMain .centerMainImg .animeImgArea img {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, 0);
}

#indexTop .mainArea .startArea .centerMain .centerMainImg .centerMainCircleImg {
  width: 67.639%;
  height: 77.533%;
  position: absolute;
  left: 50%;
  bottom: 1.963%;
  transform: translate(-50%, 0);
  z-index: 1;
}

#indexTop .mainArea .startArea #startBtn {
  display: block;
  width: 39.276%;
  margin: 0 auto;
  height: 17.57%;
  color: #605C6E;
  font-weight: 500;
  font-family: "Zen Kaku Gothic New", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  position: relative;
}

#indexTop .mainArea .startArea #startBtn:hover {
  cursor: pointer;
  color: #fff;
}

#indexTop .mainArea .startArea #startBtn:hover #startBtnPath #startBtnPathChange {
  fill: #5f5b6d;
}

#indexTop .mainArea .startArea #startBtn #startBtnPath {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

#indexTop .mainArea .startArea #startBtn #startBtnPath #startBtnPathChange {
  transition: fill .3s;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .startArea #startBtn {
    width: 63.128%;
    height: 19%;
    margin: 2% auto auto;
  }
}

#indexTop .mainArea .startArea .bottomTxt {
  text-align: center;
}

#indexTop .mainArea .startArea.isShow .animeImgArea {
  animation: animeImgCircle 10s infinite;
}

#indexTop .mainArea .choiceArea {
  width: 100%;
  height: 100%;
  padding: 10% 4.62% 31.48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .choiceArea {
    padding: 0 5.3333%;
  }
}

#indexTop .mainArea .choiceArea .choiceAreaTtlArea {
  width: 54.98%;
  margin: 0 auto;
  background-image: linear-gradient(to right, #5F5B6D 5px, transparent 5px);
  background-size: 10px 1px;
  background-repeat: repeat-x;
  background-position: left bottom;
  position: relative;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .choiceArea .choiceAreaTtlArea {
    width: 88.059%;
  }
}

#indexTop .mainArea .choiceArea .choiceAreaTtlArea:before {
  content: "";
  background: url(/images/index/indexchoiceAreaTtlSide.png) center center no-repeat;
  background-size: contain;
  position: absolute;
  width: 9.4%;
  height: 100%;
  top: 0%;
  left: 3.71%;
}

#indexTop .mainArea .choiceArea .choiceAreaTtlArea:after {
  content: "";
  background: url(/images/index/indexchoiceAreaTtlSide.png) center center no-repeat;
  background-size: contain;
  position: absolute;
  width: 9.4%;
  height: 100%;
  top: 0%;
  right: 3.71%;
}

#indexTop .mainArea .choiceArea .choiceAreaTtlArea .choiceAreaTtl {
  text-align: center;
  padding: 8.674% 0;
  background-image: linear-gradient(to right, #5F5B6D 5px, transparent 5px);
  background-size: 10px 1px;
  background-repeat: repeat-x;
  background-position: left top;
}

#indexTop .mainArea .choiceArea .choiceAreaOptions {
  position: relative;
  height: 47.55%;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .choiceArea .choiceAreaOptions {
    height: 73.294%;
  }
}

#indexTop .mainArea .choiceArea .choiceAreaOptions button {
  position: absolute;
  width: 30.661%;
  height: 100%;
  transition: transform .3s;
}

#indexTop .mainArea .choiceArea .choiceAreaOptions button:hover {
  transform: scale(1.1);
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .choiceArea .choiceAreaOptions button {
    width: 41.791%;
    height: 44.135%;
  }
}

#indexTop .mainArea .choiceArea .choiceAreaOptions button:nth-of-type(1) {
  top: 0;
  left: 0;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .choiceArea .choiceAreaOptions button:nth-of-type(1) {
    top: 21.135%;
  }
}

#indexTop .mainArea .choiceArea .choiceAreaOptions button:nth-of-type(2) {
  top: 0;
  left: 34.6938%;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .choiceArea .choiceAreaOptions button:nth-of-type(2) {
    left: 58.208%;
  }
}

#indexTop .mainArea .choiceArea .choiceAreaOptions button:nth-of-type(3) {
  top: 0;
  left: 69.3877%;
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .choiceArea .choiceAreaOptions button:nth-of-type(3) {
    top: 55.835%;
    left: 52.238%;
  }
}

#indexTop .mainArea .choiceArea .choiceAreaOptions button.notSelectButton {
  transition: opacity .5s , transform .3s;
  opacity: 0;
}

#indexTop .mainArea .choiceArea .choiceAreaOptions button.notSelectButton:hover {
  transform: scale(1);
}

#indexTop .mainArea .choiceArea .choiceAreaOptions button.selectButton {
  transition: left 1s .7s ,top 1s .7s , transform .3s;
  left: 34.6938%;
}

#indexTop .mainArea .choiceArea .choiceAreaOptions button.selectButton:hover {
  transform: scale(1);
}

@media screen and (max-width: 767px) {
  #indexTop .mainArea .choiceArea .choiceAreaOptions button.selectButton {
    top: 27.917%;
    left: 29.104%;
  }
}

@keyframes kichiAnime {
  0% {
    transform: translate(0, -100%);
    opacity: 0;
  }
  25% {
    transform: translate(0, -50%);
    opacity: 1;
  }
  50% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(0, 50%);
    opacity: .8;
  }
  100% {
    transform: translate(0, 100vh);
    opacity: 0;
  }
}

/* ==============================================
一覧ページ
============================================== */
#archiveTop .mainArea {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
}

#archiveTop .scaleArea {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  z-index: 10;
  background: #605C6E;
  width: 52.083vw;
  height: 6.25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all .6s;
}

#archiveTop .scaleArea.navIn {
  opacity: 1;
  transform: translate(-50%, 0);
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea {
    width: 100%;
    height: 18vw;
  }
}

#archiveTop .scaleArea .myResult, #archiveTop .scaleArea .rePlayLinkBtn, #archiveTop .scaleArea .playLinkBtn {
  font-size: 0.9375vw;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26%;
  font-family: "Zen Kaku Gothic New", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
  color: #fff;
  border: 1px solid #fff;
  height: 50%;
  margin: 0 2.127%;
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea .myResult, #archiveTop .scaleArea .rePlayLinkBtn, #archiveTop .scaleArea .playLinkBtn {
    width: 16.666%;
    margin: 0 2.666%;
    flex-direction: column-reverse;
    border: none;
    font-size: 3.2vw;
    height: 66.666%;
    justify-content: space-between;
  }
}

#archiveTop .scaleArea .myResult:hover {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  #archiveTop .scaleArea .myResult:hover {
    background: #fff;
  }
  #archiveTop .scaleArea .myResult:hover #myResultTxt {
    color: #605C6E;
  }
  #archiveTop .scaleArea .myResult:hover #myResultIcon {
    transform: rotate(360deg);
  }
  #archiveTop .scaleArea .myResult:hover #myResultIcon .myResultIconPath {
    fill: #605C6E;
  }
}

#archiveTop .scaleArea .myResult:hover #myResultIcon {
  transform: rotate(360deg);
}

#archiveTop .scaleArea .myResult #myResultTxt {
  transition: color .3s;
}

#archiveTop .scaleArea .myResult #myResultIcon {
  width: 10%;
  height: 43.333%;
  margin-left: 5.769%;
  transition: transform 1s .3s;
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea .myResult #myResultIcon {
    width: 37.6%;
    height: 43.193%;
    margin: 0;
  }
}

#archiveTop .scaleArea .myResult #myResultIcon .myResultIconPath {
  fill: #fff;
  transform-origin: center;
  transition: fill .3s;
}

#archiveTop .scaleArea .rePlayLinkBtn:hover {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  #archiveTop .scaleArea .rePlayLinkBtn:hover {
    background: #fff;
  }
  #archiveTop .scaleArea .rePlayLinkBtn:hover #rePlayLinkBtnTxt {
    color: #605C6E;
  }
  #archiveTop .scaleArea .rePlayLinkBtn:hover #rePlayLinkBtnIcon {
    transform: rotate(-360deg);
  }
  #archiveTop .scaleArea .rePlayLinkBtn:hover #rePlayLinkBtnIcon .rePlayLinkBtnPath {
    fill: #605C6E;
  }
}

#archiveTop .scaleArea .rePlayLinkBtn:hover #rePlayLinkBtnIcon {
  transform: rotate(-360deg);
}

#archiveTop .scaleArea .rePlayLinkBtn #rePlayLinkBtnTxt {
  transition: color .3s;
}

#archiveTop .scaleArea .rePlayLinkBtn #rePlayLinkBtnIcon {
  width: 11.923%;
  height: 43.333%;
  margin-left: 4.615%;
  transform-origin: center;
  transition: transform 1s .3s;
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea .rePlayLinkBtn #rePlayLinkBtnIcon {
    width: 33.2%;
    height: 43.193%;
    margin: 0;
  }
}

#archiveTop .scaleArea .rePlayLinkBtn #rePlayLinkBtnIcon .rePlayLinkBtnPath {
  fill: #fff;
  transition: fill .3s;
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea .playLinkBtn {
    width: 27%;
  }
}

#archiveTop .scaleArea .playLinkBtn:hover {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  #archiveTop .scaleArea .playLinkBtn:hover {
    background: #fff;
  }
  #archiveTop .scaleArea .playLinkBtn:hover .playLinkBtnTxt {
    color: #605C6E;
  }
  #archiveTop .scaleArea .playLinkBtn:hover .playLinkBtnBtnIcon .playLinkBtnBtnIconPath {
    fill: #605C6E;
  }
}

#archiveTop .scaleArea .playLinkBtn .playLinkBtnTxt {
  transition: color .3s;
}

#archiveTop .scaleArea .playLinkBtn .playLinkBtnBtnIcon {
  width: 11.923%;
  height: 43.333%;
  margin-left: 4.615%;
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea .playLinkBtn .playLinkBtnBtnIcon {
    width: 33.2%;
    height: 43.193%;
    margin: 0;
  }
}

#archiveTop .scaleArea .playLinkBtn .playLinkBtnBtnIcon .playLinkBtnBtnIconPath {
  fill: #fff;
  transition: fill .3s;
}

#archiveTop .scaleArea .sizeControlBarWrap {
  position: relative;
  height: 50%;
  width: 21.5%;
  margin: 0 2.127%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea .sizeControlBarWrap {
    width: 47.333%;
    height: 66.666%;
    margin: 0 2.666% 0 5.333%;
  }
}

#archiveTop .scaleArea .sizeControlBarWrap #scaleDownBtn {
  position: relative;
  width: 6.511%;
  height: 23.333%;
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea .sizeControlBarWrap #scaleDownBtn {
    width: 7.14%;
    height: 28.2%;
  }
}

#archiveTop .scaleArea .sizeControlBarWrap #scaleDownBtn:hover {
  cursor: pointer;
}

#archiveTop .scaleArea .sizeControlBarWrap #scaleDownBtn:before {
  content: "";
  width: 100%;
  height: 0.15625vw;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea .sizeControlBarWrap #scaleDownBtn:before {
    height: 0.4vw;
  }
}

#archiveTop .scaleArea .sizeControlBarWrap #scaleUpBtn {
  position: relative;
  width: 6.511%;
  height: 23.333%;
}

#archiveTop .scaleArea .sizeControlBarWrap #scaleUpBtn:hover {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea .sizeControlBarWrap #scaleUpBtn {
    width: 7.14%;
    height: 28.2%;
  }
}

#archiveTop .scaleArea .sizeControlBarWrap #scaleUpBtn:before {
  content: "";
  width: 100%;
  height: 0.15625vw;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea .sizeControlBarWrap #scaleUpBtn:before {
    height: 0.4vw;
  }
}

#archiveTop .scaleArea .sizeControlBarWrap #scaleUpBtn:after {
  content: "";
  width: 0.15625vw;
  height: 100%;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea .sizeControlBarWrap #scaleUpBtn:after {
    width: 0.4vw;
  }
}

#archiveTop .scaleArea .sizeControlBarWrap .sizeControlMaxBar {
  position: relative;
  width: 69.76%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea .sizeControlBarWrap .sizeControlMaxBar {
    width: 71.83%;
  }
}

#archiveTop .scaleArea .sizeControlBarWrap .sizeControlMaxBar:before {
  content: "";
  width: 100%;
  height: 3px;
  background: #fff;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea .sizeControlBarWrap .sizeControlMaxBar:before {
    height: 0.4vw;
  }
}

#archiveTop .scaleArea .sizeControlBarWrap .sizeControlMaxBar #sizeControlBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  cursor: pointer;
}

#archiveTop .scaleArea .sizeControlBarWrap .sizeControlMaxBar #sizeControlBar .icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -40%);
  margin: 0;
  background: url(/images/archive/archive_scale_icon.svg) center center no-repeat;
  background-size: contain;
  width: 1.8229vw;
  height: 1.8229vw;
}

@media screen and (max-width: 767px) {
  #archiveTop .scaleArea .sizeControlBarWrap .sizeControlMaxBar #sizeControlBar .icon {
    width: 6.074vw;
    height: 6.074vw;
  }
}

#archiveTop #targetElement {
  width: 190.625vw;
  position: relative;
  z-index: 1;
  cursor: grab;
  transform-origin: top left;
  will-change: width, height, top, left;
}

#archiveTop #targetElement.dragging {
  cursor: grabbing;
}

@media screen and (max-width: 767px) {
  #archiveTop #targetElement {
    width: 200vw;
  }
}

#archiveTop #targetElement .targetElementInner {
  padding-top: 100%;
  position: relative;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #archiveTop #targetElement .targetElementInner {
    padding-top: 541%;
  }
}

#archiveTop #targetElement .targetElementInner .inner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  top: 0;
  left: 0;
  padding: 3.9vw 3.9vw 7.8125vw 3.9vw;
}

@media screen and (max-width: 767px) {
  #archiveTop #targetElement .targetElementInner .inner {
    grid-template-columns: 25% 25% 25% 25%;
    padding: 11vw 8vw 18vw 8vw;
  }
}

#archiveTop #targetElement .targetElementInner .inner .card {
  width: 85.47%;
  display: inline-block;
  position: relative;
  height: 87.336%;
  margin: auto;
  opacity: 0;
  transition: opacity .5s;
}

@media screen and (max-width: 767px) {
  #archiveTop #targetElement .targetElementInner .inner .card {
    width: 86.9565%;
    height: 94.5239%;
  }
}

#archiveTop #targetElement .targetElementInner .inner .card .postCateThumbnail {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

#archiveTop #targetElement .targetElementInner .inner .card .fortuneResultImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 83.33%;
  width: 83.33%;
  object-fit: contain;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
}

#archiveTop #targetElement .targetElementInner .inner .card .viewCount {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 8%;
  padding: 0 8.335%;
}

#archiveTop #targetElement .targetElementInner .inner .card .viewCount .countIcon {
  width: 7%;
}

#archiveTop #targetElement .targetElementInner .inner .card .viewCount .viewCountNum {
  font-weight: bold;
  padding-left: .25em;
  line-height: 1;
}

#archiveTop #targetElement .targetElementInner .inner .card#myResultCard:before {
  content: "";
  background: url(/images/archive/archive_my_result_bg.png) top center no-repeat;
  background-size: contain;
  width: 38.33%;
  height: 38.33%;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 2;
  opacity: 0;
  transition: opacity .3s;
}

#archiveTop #targetElement .targetElementInner .inner .card#myResultCard.finishAnime:before {
  opacity: 1;
}

/* ==============================================
詳細ページ
============================================== */
#singleTop .mainArea {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
}

#singleTop .mainArea.isShow {
  display: block;
  opacity: 1;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 9.259% 7.407% 12.962%;
}

@media screen and (max-width: 767px) {
  #singleTop .mainArea .mainContentBox .mainContentBoxInner {
    padding: 0 5.333% 3.468%;
  }
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .centerMainImg {
  width: 80.879%;
  height: 77.305%;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 767px) {
  #singleTop .mainArea .mainContentBox .mainContentBoxInner .centerMainImg {
    width: 100%;
    height: 72.23%;
  }
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .centerMainImg .animeImgArea {
  position: relative;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: center;
  z-index: 0;
}

@media screen and (max-width: 767px) {
  #singleTop .mainArea .mainContentBox .mainContentBoxInner .centerMainImg .animeImgArea {
    height: 95.238%;
  }
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .centerMainImg .animeImgArea img {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, 0);
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .centerMainImg .centerMainCircleImg {
  width: 74.052%;
  height: 84.745%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  z-index: 1;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 767px) {
  #singleTop .mainArea .mainContentBox .mainContentBoxInner .centerMainImg .centerMainCircleImg {
    width: 82.335%;
    height: 91.035%;
  }
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .snsArea {
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.876%;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  #singleTop .mainArea .mainContentBox .mainContentBoxInner .snsArea {
    height: 6.68%;
    margin-bottom: 3.5%;
  }
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .snsArea a {
  width: 3.804%;
  height: 100%;
  margin: 0 1.2%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  #singleTop .mainArea .mainContentBox .mainContentBoxInner .snsArea a {
    width: 8.779%;
    margin: 0 2%;
  }
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .snsArea a.igLink img {
  width: 69.23%;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .snsArea a.xLink img {
  width: 66.152%;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .snsArea a.fbLink img {
  width: 41.395%;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .snsArea a.lineLink img {
  width: 100%;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .snsArea a.threadsLink img {
  width: 64.493%;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea {
  display: flex;
  justify-content: space-between;
  border-image-width: 100%;
  height: 8.392%;
  position: relative;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .replayAnnounceArea {
  position: absolute;
  top: 0;
  right: 0;
  width: 30.434%;
  text-align: center;
  opacity: 0;
  transition: transform .5s 2.3s , opacity .4s 2.4s;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .replayAnnounceArea.slideIn {
  transform: translate(0, -100%);
  opacity: 1;
}

@media screen and (max-width: 767px) {
  #singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .replayAnnounceArea {
    width: 31.343%;
  }
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .replayAnnounceArea .replayAnnounceTxt {
  line-height: 2;
  position: relative;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .replayAnnounceArea .replayAnnounceTxt::before {
  content: "";
  width: 2px;
  height: 1em;
  border-radius: 2px;
  background: #605C6E;
  position: absolute;
  bottom: .1em;
  left: -.5em;
  transform: rotate(-20deg);
}

@media screen and (max-width: 767px) {
  #singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .replayAnnounceArea .replayAnnounceTxt::before {
    width: 1px;
  }
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .replayAnnounceArea .replayAnnounceTxt::after {
  content: "";
  width: 2px;
  height: 1em;
  border-radius: 2px;
  background: #605C6E;
  position: absolute;
  bottom: .1em;
  right: -.5em;
  transform: rotate(20deg);
}

@media screen and (max-width: 767px) {
  #singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .replayAnnounceArea .replayAnnounceTxt::after {
    width: 1px;
  }
}

@media screen and (max-width: 767px) {
  #singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea {
    height: 13.112%;
  }
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea #download {
  display: none;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea #downloadBtn, #singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .archiveLink, #singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .rePlayBtn {
  width: 30.434%;
  background: url(/images/single/linkBtnFrame.png) center center no-repeat;
  background-size: contain;
  height: 100%;
  font-family: "Zen Kaku Gothic New", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
  color: #605C6E;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background .3s;
}

@media screen and (max-width: 767px) {
  #singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea #downloadBtn, #singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .archiveLink, #singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .rePlayBtn {
    width: 31.343%;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 1.79% 0;
    background: none;
    border: 1px solid #605C6E;
  }
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea #downloadBtn:hover {
  cursor: pointer;
  background: #605C6E;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea #downloadBtn:hover #downloadBtnTxt {
  color: #fff;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea #downloadBtn:hover #downloadBtnIcon .downloadBtnIconPath {
  fill: #fff;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea #downloadBtn:hover #downloadBtnIcon .downloadBtnIconPath.downloadBtnIconPathMove {
  transform: translate(0, 10%);
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea #downloadBtn #downloadBtnTxt {
  transition: color .3s;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea #downloadBtn #downloadBtnIcon {
  width: 8.085%;
  height: 24.83%;
  margin-left: 4.28%;
}

@media screen and (max-width: 767px) {
  #singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea #downloadBtn #downloadBtnIcon {
    width: 14.761%;
    height: 29.268%;
    margin: 3% 0 0 0;
  }
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea #downloadBtn #downloadBtnIcon .downloadBtnIconPath {
  fill: #605C6E;
  transition: fill .3s , transform .5s .3s;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea #downloadBtn #downloadBtnIcon .downloadBtnIconPath.downloadBtnIconPathMove {
  transform: translate(0, -5%);
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .archiveLink:hover {
  cursor: pointer;
  background: #605C6E;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .archiveLink:hover #archiveLinkTxt {
  color: #fff;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .archiveLink:hover #archiveLinkIcon {
  transform: rotate(360deg);
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .archiveLink:hover #archiveLinkIcon .archiveLinkIconPath {
  fill: #fff;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .archiveLink #archiveLinkTxt {
  transition: color .3s;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .archiveLink #archiveLinkIcon {
  width: 9.571%;
  height: 38.01%;
  margin-left: 4.28%;
  transition: transform 1s .3s;
}

@media screen and (max-width: 767px) {
  #singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .archiveLink #archiveLinkIcon {
    width: 19.919%;
    height: 51.012%;
    margin: 0;
  }
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .archiveLink #archiveLinkIcon .archiveLinkIconPath {
  fill: #605C6E;
  transform-origin: center;
  transition: fill .3s;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .rePlayBtn:hover {
  cursor: pointer;
  background: #605C6E;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .rePlayBtn:hover #rePlayBtnTxt {
  color: #fff;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .rePlayBtn:hover #rePlayBtnIcon {
  transform: rotate(-360deg);
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .rePlayBtn:hover #rePlayBtnIcon .rePlayBtnPath {
  fill: #fff;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .rePlayBtn #rePlayBtnTxt {
  transition: color .3s;
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .rePlayBtn #rePlayBtnIcon {
  width: 9.571%;
  height: 38.01%;
  margin-left: 4.28%;
  transform-origin: center;
  transition: transform 1s .3s;
}

@media screen and (max-width: 767px) {
  #singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .rePlayBtn #rePlayBtnIcon {
    width: 19.919%;
    height: 51.012%;
    margin: 0;
  }
}

#singleTop .mainArea .mainContentBox .mainContentBoxInner .btnArea .rePlayBtn #rePlayBtnIcon .rePlayBtnPath {
  fill: #605C6E;
  transition: fill .3s;
}

#singleTop .mainArea .mainContentBox.isShow .animeImgArea {
  animation: animeImgCircle 10s infinite;
}

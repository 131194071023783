@charset "utf-8";

.columnLayout {
	display: flex;
	justify-content: space-between;
	@for $i from 1 through 8 {
		$width: percentage(1 / $i);
		.col#{$i} {
			flex-basis: floor($width);
		}
	}
}

// ローディング画面
#loading {
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: auto;
	min-width: 100%;
	height: auto;
	min-height: 100%;
	background: #ffffff;
	opacity: 1;
	z-index: 100000;
}

.hoverAnime{
  img{
    transition: transform .3s;
  }
  &:hover{
    cursor: pointer;

    img{
      transform: scale(1.2);
    }
  }
}

.mainContentBox{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    opacity: 0;
    transition: opacity 1s;

    &.isShow{
      opacity: 1;
    }

    .introTxtArea,.startArea,.choiceArea{
      visibility: hidden;
      opacity: 0;
      transition: opacity 1s;

      &.isIn{
        visibility: visible;
      }

      &.isShow{
        opacity: 1;
      }

      &.hide{
        opacity: 0;
        display: none !important;
      }
    }

  }


    /*-----------------------------------
    背景のスタイル
    -----------------------------------*/
  .bgContent{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s;

    &.isShow{
      opacity: 1;
    }

    .cornerImg{
      position: absolute;
      width: 100%;
      height: 100%;

      .corner{
        width: 8.48%;
        max-width: 163px;

        @include mq(md){
          width: 9.581%;
          max-width: 75px;
        }

        &.corner01{
          position: absolute;
          left: 4.166%;
          top: 4.629%;

          @include mq(md){
            left: 5.333%;
            top: 2.717%;
          }
        }
  
        &.corner02{
          position: absolute;
          right: 4.166%;
          top: 4.629%;
          @include mq(md){
            right: 5.333%;
            top: 2.717%;
          }
        }
  
        &.corner03{
          position: absolute;
          right: 4.166%;
          bottom: 4.629%;
          @include mq(md){
            right: 5.333%;
            bottom: 2.717%;
          }
        }
  
        &.corner04{
          position: absolute;
          left: 4.166%;
          bottom: 4.629%;
          @include mq(md){
            left: 5.333%;
            bottom: 2.717%;
          }
        }
      }

      .side{
        height: 47.96%;
        width: 1.23%;
        position: absolute;

        &.side01{
          left: 4.166%;
          top: 50%;
          transform: translate(0,-50%);
        }
  
        &.side02{
          right: 4.166%;
          top: 50%;
          transform: translate(0,-50%);
        }

        @include mq(md){
          width: 54.4%;
          height: 2.397%;

          &.side03{
            top: 2.717%;
            left: 50%;
            transform: translate(-50% , 0);
          }

          &.side04{
            bottom: 2.717%;
            left: 50%;
            transform: translate(-50% , 0);
          }
        }
      }

      .cornerSolid{
        width: 8.802%;
        height: 8.611%;
        position: absolute;

        &.cornerSolid01{
          top: 13.88%;
          left: 13.02%;
        }
        &.cornerSolid02{
          top: 13.88%;
          right: 13.02%;
        }
        &.cornerSolid03{
          bottom: 13.88%;
          right: 13.02%;
        }
        &.cornerSolid04{
          bottom: 13.88%;
          left: 13.02%;
        }
      }
    }

    .cloudImg{
      position: absolute;
      width: 100%;
      height: 100%;
      transition: opacity 1s;

      &.hide{
        opacity: 0;
      }

      .cloud{
        opacity: 0;
      }

      .cloud01{
        position: absolute;
        width: 21.82%;
        height: 11.2%;
        top: 30.18%;
        left: 11.56%;

        @include mq(md){
          width: 46.273%;
          height: 9.057%;
          top: 13.134%;
          left: 0;
        }
      }

      .cloud02{
        position: absolute;
        width: 20.36%;
        height: 9.81%;
        top: 46.66%;
        left: .46%;
      }

      .cloud03{
        position: absolute;
        width: 14.42%;
        height: 9.72%;
        bottom: 29.25%;
        left: 14.11%;

        @include mq(md){
          width: 36.056%;
          height: 9.355%;
          bottom: 15.579%;
          left: 0;
        }
      }

      .cloud04{
        position: absolute;
        width: 23.95%;
        height: 11.2%;
        top: 31.75%;
        right: 4.375%;
      }

      .cloud05{
        position: absolute;
        width: 18.07%;
        height: 15.74%;
        bottom: 35.5%;
        right: 10.67%;

        @include mq(md){
          width: 35.466%;
          height: 11.775%;
          bottom: 25.905%;
          right: 0;
        }
      }
    }

  }



  .paperAnime{
    position: fixed;
    z-index: 100000;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;

    @include mq(md){
      width: 1920px;
      height: 1080px;
      transform: translate(-50%,-50%)rotate(90deg);
    }

    svg{
      width: 100%;
      height: 100%;

      .st0{
        fill:#EDE1CA !important;
        stroke:#EDE1CA !important;
        will-change: transform, opacity;
        shape-rendering: optimizeQuality; 
      }
    }

    &.isShow{
      visibility: visible;
      opacity: 1;
    }
  }
@charset "utf-8";

/* =====================================
ヘッダー
===================================== */

#header {
  @include props(mt, 100);
  @include props(mb, 100);
  @include props(pt, 100);
  @include props(pb, 100);
  @include props(w, 100);
  @include fz_vw(16);
}
@charset "utf-8";
/* ==============================================
トップページ
============================================== */

#indexTop .mainArea{
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);

  /*-----------------------------------
  introArea
  -----------------------------------*/
  .introArea{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 1s;

    &.hide{
      opacity: 0;
    }

    .kichiPartsBox{
      position: relative;
      width: 100%;
      height: 100%;

      img{
        position: absolute;
        top: 0;
        opacity: 0;

        &.parts01{
          left: 0;
          right: 0;
          margin: 0 auto;
          animation: kichiAnime linear 20s 0s infinite;
          width: 91%;
        }
        &.parts02{
          left: 3%;
          right: 0;
          margin: 0 auto;
          animation: kichiAnime linear 16s 0s infinite;
          width: 94%;
        }
        &.parts03{
          left: 0;
          right: 0;
          margin: 0 auto;
          animation: kichiAnime linear 30s 3s infinite;
          width: 94%;
        }
      }
    }
  }


  /*-----------------------------------
  introTxtArea
  -----------------------------------*/
  .introTxtArea{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq(md){
      align-items: flex-start;
    }

    .introTxtAreaInner{
      width: 81.203%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      position: relative;

      @include mq(md){
        // width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        height: 100%;
      }
      &:before{
        content: "";
        background: url(/images/index/index_intro_right_icon.svg) bottom center no-repeat;
        background-size: contain;
        width: 15.25%;
        height: 100%;
        display: inline-block;
        margin-left: auto;
        position: absolute;
        bottom: 0;
        right: 0;

        @include mq(md){
          content: none;
        }
      }

      &:after{
        content: "";
        background: url(/images/index/index_intro_left_icon.svg) bottom center no-repeat;
        background-size: contain;
        width: 15.25%;
        height: 100%;
        display: inline-block;
        margin-right: auto;
        position: absolute;
        bottom: 0;
        left: 0;

        @include mq(md){
          background: url(/images/index/index_intro_center_icon.svg) bottom center no-repeat;
          background-size: contain;
          width: 100%;
          height: 33.526%;
          margin: auto 0 0;

        }
      }

      .siteTtl{
        letter-spacing: .075em;
        writing-mode: vertical-rl;
        width: 1em;
        line-height: 1;
      }

      .siteDesc{
        line-height: 1.8;
        letter-spacing: .075em;
        margin-right: 2em;
        writing-mode: vertical-rl;

        @include mq(md){
          line-height: 1.7;
          width: 13.6em;
        }
        span{
          writing-mode: horizontal-tb;
          font-family: $base-font;
          line-height: 1.4;
          letter-spacing: .075em;
          font-weight: 500;
        }
        
      }
    }
  }

  /*-----------------------------------
  startArea
  -----------------------------------*/
  .startArea{
    padding: 13.888% 9.2592% 8.055%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mq(md){
      padding: 0 5.3333%;
    }

    .centerMain{
      height: 72.632%;
      position: relative;

      @include mq(md){
        height: 67.514%;
      }

      .rightTxt{
        position: absolute;
        right: 0;
        bottom: 0;
        writing-mode: vertical-rl;
        height: 77.533%;
        @include mq(pc){
          display: flex;
          justify-content: center;
        }
      }

      .leftTxt{
        position: absolute;
        left: 0;
        bottom: 0;
        writing-mode: vertical-rl;
        height: 77.533%;
        @include mq(pc){
          display: flex;
          justify-content: center;
        }

        .leftTxtNum{
          writing-mode: horizontal-tb;
        }
      }

      .centerMainImg{
        height: 100%;
        width: 79.834%;
        margin: 0 auto;
        position: relative;

        @include mq(md){
          width: 100%;
        }

        .animeImgArea{
          position: relative;
          width: 100%;
          height: 100%;
          transform: scale(0);
          transform-origin: center;
          z-index: 0;

          img{
            position: absolute;
            top: 0;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%,0);
          }
        }

        .centerMainCircleImg{
          width: 67.639%;
          height: 77.533%;
          position: absolute;
          left: 50%;
          bottom: 1.963%;
          transform: translate(-50% , 0);
          z-index: 1;
        }
      }
    }
    
    #startBtn{
      display: block;
      width: 39.276%;
      margin: 0 auto;
      height: 17.57%;
      color: $basicColor;
      font-weight: 500;
      font-family: $base-font;
      position: relative;

      &:hover{
        cursor: pointer;
        color: $basicWhiteColor;
        #startBtnPath{
          #startBtnPathChange{
            fill: #5f5b6d;
          }
        }
      }

      #startBtnPath{
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;

        #startBtnPathChange{
          transition: fill .3s;
        }
      }

      @include mq(md){
        width: 63.128%;
        height: 19%;
        margin: 2% auto auto;
      }
    }

    .bottomTxt{
      text-align: center;
    }

    &.isShow{
      .animeImgArea{
        animation: animeImgCircle 10s infinite;
      }
    }
  }

  /*-----------------------------------
  choiceArea
  -----------------------------------*/
  .choiceArea{
    width: 100%;
    height: 100%;
    padding: 10% 4.62% 31.48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mq(md){
      padding: 0 5.3333%;
    }

    .choiceAreaTtlArea{
      width: 54.98%;
      margin: 0 auto;
      background-image : linear-gradient(to right, #5F5B6D 5px, transparent 5px);
      background-size: 10px 1px;
      background-repeat: repeat-x; 
      background-position: left bottom;
      position: relative;

      @include mq(md){
        width: 88.059%;
      }

      &:before{
        content: "";
        background: url(/images/index/indexchoiceAreaTtlSide.png) center center no-repeat;
        background-size: contain;
        position: absolute;
        width: 9.4%;
        height: 100%;
        top: 0%;
        left: 3.71%;
      }

      &:after{
        content: "";
        background: url(/images/index/indexchoiceAreaTtlSide.png) center center no-repeat;
        background-size: contain;
        position: absolute;
        width: 9.4%;
        height: 100%;
        top: 0%;
        right: 3.71%;
      }

      .choiceAreaTtl{
        text-align: center;
        padding: 8.674% 0;
        background-image : linear-gradient(to right, #5F5B6D 5px, transparent 5px);
        background-size: 10px 1px;
        background-repeat: repeat-x; 
        background-position: left top;
      }
    }

    .choiceAreaOptions{
      position: relative;
      height: 47.55%;

      @include mq(md){
        height: 73.294%;
      }

      button{
        position: absolute;
        width: 30.661%;
        height: 100%;
        transition: transform .3s;

        &:hover{
          transform: scale(1.1);
          cursor: pointer;
        }

        @include mq(md){
          width: 41.791%;
          height: 44.135%;
        }

        &:nth-of-type(1){
          top: 0;
          left: 0;

          @include mq(md){
            top: 21.135%;
          }
        }
        &:nth-of-type(2){
          top: 0;
          left: 34.6938%;

          @include mq(md){
            left: 58.208%;
          }
        }
        &:nth-of-type(3){
          top: 0;
          left: 69.3877%;

          @include mq(md){
            top: 55.835%;
            left: 52.238%;
          }
        }

        &.notSelectButton{
          transition: opacity .5s , transform .3s;
          opacity: 0;

          &:hover{
            transform: scale(1);
          }
        }

        &.selectButton{
          transition: left 1s .7s ,top 1s .7s , transform .3s;
          left: 34.6938%;

          &:hover{
            transform: scale(1);
          }

          @include mq(md){
            top: 27.917%;
            left: 29.104%;
          }
        }
      }
    }
  }
}

@keyframes kichiAnime {
    0% {
      transform:translate(0,-100%);
      opacity: 0;
    }
    25% {
      transform:translate(0,-50%);
      opacity: 1;
    }
    50% {
      transform:translate(0,0);
    }
    75% {
      transform:translate(0,50%);
      opacity: .8;
    }
    100% {
      transform:translate(0,100vh);
      opacity: 0;
    }
}

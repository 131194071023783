@charset "utf-8";
/* アニメーション関連---------------------- */
.fadeIn {
  $fadeIn: &;
  opacity: 0;
  visibility:hidden;
  transform: translateY(100px);
  transition: opacity 1s .5s, transform 1s .5s;
  backface-visibility: hidden;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: none;
    #{$fadeIn}_second {
      opacity: 1;
      visibility: visible;
    }
    #{$fadeIn}_third {
      opacity: 1;
      visibility: visible;
      transform: none;
    }
  }
  #{$fadeIn}_second {
    opacity: 0;
    visibility:hidden;
    transition: opacity .8s 1s;
  }
  #{$fadeIn}_third {
    opacity: 0;
    visibility:hidden;
    transform: translateY(30px);
    transition: opacity .8s .7s, transform .8s .7s;
  }
}

@keyframes animeImgCircle {
  0%{
    transform: scale(0);
  }
  20%{
    transform: scale(1);
  }
  60%{
    transform: scale(1);
  }
  95%{
    transform: scale(1);
    opacity: 1;
  }
  100%{
    transform: scale(1);
    opacity: 0;
  }
}
@charset "utf-8";
/* ==============================================
一覧ページ
============================================== */

#archiveTop{
	.mainArea{
		width: 100%;
		height: calc(var(--vh, 1vh) * 100);
		position: fixed;
	}

	.scaleArea{
		position: fixed;
		bottom: 0;
		left: 50%;
		transform: translate(-50%,100%);
		z-index: 10;
		background: #605C6E;
		width: 52.083vw;
		height: 6.25vw;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0;
		transition: all .6s;

		&.navIn{
			opacity: 1;
			transform: translate(-50%,0);
		}

		@include mq(md){
			width: 100%;
			height: 18vw;
		}


		.myResult,.rePlayLinkBtn,.playLinkBtn{
			font-size: 0.9375vw;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 26%;
			font-family: $base-font;
			font-weight: 500;
			color: $basicWhiteColor;
			border: 1px solid $basicWhiteColor;
			height: 50%;
			margin: 0 2.127%;

			@include mq(md){
				width: 16.666%;
				margin: 0 2.666%;
				flex-direction: column-reverse;
				border: none;
				font-size: 3.2vw;
				height: 66.666%;
				justify-content: space-between;
			}
		}

		.myResult{
			&:hover{
				cursor: pointer;

				@include mq(pc){
					background: $basicWhiteColor;
					#myResultTxt{
						color: $basicColor;
					}
					#myResultIcon{
						transform: rotate(360deg);
						.myResultIconPath{
							fill: $basicColor;
						}
					}
				}
				#myResultIcon{
					transform: rotate(360deg);
				}

			}
			#myResultTxt{
				transition: color .3s;
			}
			#myResultIcon{
				width: 10%;
				height: 43.333%;
				margin-left: 5.769%;
				transition: transform 1s .3s;

				@include mq(md){
					width: 37.6%;
					height: 43.193%;
					margin: 0;
				}

				.myResultIconPath{
					fill: $basicWhiteColor;
					transform-origin: center;
					transition: fill .3s;
				}
			}
		}

		.rePlayLinkBtn{
			&:hover{
				cursor: pointer;
				@include mq(pc){
					background: $basicWhiteColor;

					#rePlayLinkBtnTxt{
						color: $basicColor;
					}
					#rePlayLinkBtnIcon{
						transform: rotate(-360deg);
						.rePlayLinkBtnPath{
							fill: $basicColor;
						}
					}
				}
				#rePlayLinkBtnIcon{
					transform: rotate(-360deg);
				}
				
			}
			#rePlayLinkBtnTxt{
				transition: color .3s;
			}
			#rePlayLinkBtnIcon{
				width: 11.923%;
				height: 43.333%;
				margin-left: 4.615%;
				transform-origin: center;
				transition: transform 1s .3s;

				@include mq(md){
					width: 33.2%;
					height: 43.193%;
					margin: 0;
				}

				.rePlayLinkBtnPath{
					fill: $basicWhiteColor;
					transition: fill .3s;
				}
			}
		}

		.playLinkBtn{
			@include mq(md){
				width: 27%;
			}
			&:hover{
				cursor: pointer;
				@include mq(pc){
					background: $basicWhiteColor;

					.playLinkBtnTxt{
						color: $basicColor;
					}
					.playLinkBtnBtnIcon{
						.playLinkBtnBtnIconPath{
							fill: $basicColor;
						}
					}
				}
				
			}
			.playLinkBtnTxt{
				transition: color .3s;
			}
			.playLinkBtnBtnIcon{
				width: 11.923%;
				height: 43.333%;
				margin-left: 4.615%;

				@include mq(md){
					width: 33.2%;
					height: 43.193%;
					margin: 0;
				}

				.playLinkBtnBtnIconPath{
					fill: $basicWhiteColor;
					transition: fill .3s;
				}
			}
		}

		.sizeControlBarWrap {
			position: relative;
			height: 50%;
			width: 21.5%;
			margin: 0 2.127%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@include mq(md){
				width: 47.333%;
				height: 66.666%;
				margin: 0 2.666% 0 5.333%;
			}

			#scaleDownBtn{
				position: relative;
				width: 6.511%;
				height: 23.333%;

				@include mq(md){
					width: 7.14%;
					height: 28.2%;
				}

				&:hover{
					cursor: pointer;
				}

				&:before{
					content: "";
					width: 100%;
					height: 0.15625vw;
					background: $basicWhiteColor;
					border-radius: 3px;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translate(0,-50%);

					@include mq(md){
						height: 0.4vw;
					}
				}
			}

			#scaleUpBtn{
				position: relative;
				width: 6.511%;
				height: 23.333%;

				&:hover{
					cursor: pointer;
				}

				@include mq(md){
					width: 7.14%;
					height: 28.2%;
				}

				&:before{
					content: "";
					width: 100%;
					height: 0.15625vw;
					background: $basicWhiteColor;
					border-radius: 3px;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translate(0,-50%);

					@include mq(md){
						height: 0.4vw;
					}
				}

				&:after{
					content: "";
					width: 0.15625vw;
					height: 100%;
					background: $basicWhiteColor;
					border-radius: 3px;
					position: absolute;
					top: 0;
					left: 50%;
					transform: translate(-50%,0);

					@include mq(md){
						width: 0.4vw;
					}
				}
			}
	
			.sizeControlMaxBar{
				position: relative;
				width: 69.76%;
				height: 100%;

				@include mq(md){
					width: 71.83%;
				}

				&:before{
					content: "";
					width: 100%;
					height: 3px;
					background: $basicWhiteColor;
					border-radius: 2px;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translate(0,-50%);

					@include mq(md){
						height: 0.4vw;
					}
				}

				#sizeControlBar {
					position: absolute;
					top: 0;
					left: 0;
					width: 50%;
					height: 100%;
					cursor: pointer;
		
					.icon{
						position: absolute;
						top: 50%;
						right: 0;
						transform: translate(50% , -40%);
						margin: 0;
						background: url(/images/archive/archive_scale_icon.svg) center center no-repeat;
						background-size: contain;
						width: 1.8229vw;
						height: 1.8229vw;

						@include mq(md){
							width: 6.074vw;
							height: 6.074vw;
						}
					}
				}
			}
		}

	}

	#targetElement {
		width: 190.625vw;
		position: relative;
		z-index: 1;
		cursor: grab;
		transform-origin: top left;
		will-change: width, height, top, left;

		&.dragging{
			cursor: grabbing;
		}

		@include mq(md){
			width: 200vw;
		}

		.targetElementInner{
			padding-top: 100%;
			position: relative;
			z-index: -1;

			@include mq(md){
				padding-top: 541%;
			}

			.inner{
				position: absolute;
				width: 100%;
				height: 100%;
				display: grid;
				grid-template-columns:10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
				top: 0;
				left: 0;
				padding: 3.9vw 3.9vw 7.8125vw 3.9vw;

				@include mq(md){
					grid-template-columns:25% 25% 25% 25%;
					padding: 11vw 8vw 18vw 8vw;
				}

				.card{
					width: 85.47%;
					display: inline-block;
					position: relative;
					height: 87.336%;
					margin: auto;
					opacity: 0;
					transition: opacity .5s;

					@include mq(md){
						width: 86.9565%;
						height: 94.5239%;
					}

					.postCateThumbnail{
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						object-fit: contain;
					}

					.fortuneResultImg{
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50% , -50%);
						height: 83.33%;
						width: 83.33%;
						object-fit: contain;
						box-shadow: 0px 0px 6px 0px rgba(0,0,0,.2);
					}

					.viewCount{
						position: absolute;
						display: flex;
						align-items: center;
						top: 0;
						left: 0;
						width: 100%;
						height: 8%;
						padding: 0 8.335%;

						.countIcon{
							width: 7%;
						}

						.viewCountNum{
							font-weight: bold;
							padding-left: .25em;
							line-height: 1;
						}
					}

					&#myResultCard{
						&:before{
							content: "";
							background: url(/images/archive/archive_my_result_bg.png) top center no-repeat;
							background-size: contain;
							width: 38.33%;
							height: 38.33%;
							position: absolute;
							top: 0;
							right: 0;
							transform: translate(50%,-50%);
							z-index: 2;
							opacity: 0;
							transition: opacity .3s;
						}
						&.finishAnime{
							&:before{
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}

}
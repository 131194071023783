@charset "utf-8";
/* ==============================================
詳細ページ
============================================== */

#singleTop{
	.mainArea{
		width: 100%;
		height: calc(var(--vh, 1vh) * 100);

		&.isShow{
			display: block;
			opacity: 1;
		}

		.mainContentBox{
			.mainContentBoxInner{
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 9.259% 7.407% 12.962%;

				@include mq(md){
					padding: 0 5.333% 3.468%;
				}

				.centerMainImg{
					width: 80.879%;
					height: 77.305%;
					margin: 0 auto;
					position: relative;

					@include mq(md){
						width: 100%;
						height: 72.23%;
					}
	
					.animeImgArea{
						position: relative;
						width: 100%;
						height: 100%;
						transform: scale(0);
						transform-origin: center;
						z-index: 0;

						@include mq(md){
							height: 95.238%;
						}
			  
						img{
						 	position: absolute;
						 	top: 0;
						 	left: 50%;
						 	width: 100%;
						 	height: 100%;
						 	transform: translate(-50%,0);
						}
					  }
	
					.centerMainCircleImg{
						width: 74.052%;
						height: 84.745%;
						position: absolute;
						left: 50%;
						bottom: 0;
						transform: translate(-50% , 0);
						z-index: 1;
						box-shadow: 0px 0px 6px 0px rgba(0,0,0,.2);

						@include mq(md){
							width: 82.335%;
							height: 91.035%;
						}
					}
				}


				.snsArea{
					display: flex;
					align-items: center;
					width: 100%;
					height: 3.876%;
					justify-content: center;

					@include mq(md){
						height: 6.68%;
						margin-bottom: 3.5%;
					}

					a{
						width: 3.804%;
						height: 100%;
						margin: 0 1.2%;
						display: flex;
						align-items: center;
						justify-content: center;
						@include mq(md){
							width: 8.779%;
							margin: 0 2%;
						}

						&.igLink{
							img{
								width: 69.23%;
							}
						}

						&.xLink{
							img{
								width: 66.152%;
							}
						}

						&.fbLink{
							img{
								width: 41.395%;
							}
						}

						&.lineLink{
							img{
								width: 100%;
							}
						}

						&.threadsLink{
							img{
								width: 64.493%;
							}
						}
					}
				}


				.btnArea{
					display: flex;
					justify-content: space-between;
					border-image-width: 100%;
					height: 8.392%;
					position: relative;

					.replayAnnounceArea{
						position: absolute;
						top: 0;
						right: 0;
						width: 30.434%;
						text-align: center;
						opacity: 0;
						transition: transform .5s 2.3s , opacity .4s 2.4s;

						&.slideIn{
							transform: translate(0,-100%);
							opacity: 1;
						}

						@include mq(md){
							width: 31.343%;
						}

						.replayAnnounceTxt{
							line-height: 2;
							position: relative;

							&::before{
								content: "";
								width: 2px;
								height: 1em;
								border-radius: 2px;
								background: $basicColor;
								position: absolute;
								bottom: .1em;
								left: -.5em;
								transform: rotate(-20deg);

								@include mq(md){
									width: 1px;
								}
							}

							&::after{
								content: "";
								width: 2px;
								height: 1em;
								border-radius: 2px;
								background: $basicColor;
								position: absolute;
								bottom: .1em;
								right: -.5em;
								transform: rotate(20deg);

								@include mq(md){
									width: 1px;
								}
							}
						}
					}

					@include mq(md){
						height: 13.112%;
					}

					#download{
						display: none;
					}

					#downloadBtn,.archiveLink,.rePlayBtn{
						width: 30.434%;
						background: url(/images/single/linkBtnFrame.png) center center no-repeat;
						background-size: contain;
						height: 100%;
						font-family: $base-font;
						font-weight: 500;
						color: $basicColor;
						display: flex;
						align-items: center;
						justify-content: center;
						transition: background .3s;

						@include mq(md){
							width: 31.343%;
							flex-direction: column-reverse;
							justify-content: space-between;
							padding: 1.79% 0;
							background: none;
							border: 1px solid $basicColor;
						}
					}

					#downloadBtn{
						&:hover{
							cursor: pointer;
							background: $basicColor;
							#downloadBtnTxt{
								color: $basicWhiteColor;
							}
							#downloadBtnIcon{
								.downloadBtnIconPath{
									fill: $basicWhiteColor;

									&.downloadBtnIconPathMove{
										transform: translate(0, 10%);
									}
								}
							}
						}
						#downloadBtnTxt{
							transition: color .3s;
						}
						#downloadBtnIcon{
							width: 8.085%;
							height: 24.83%;
							margin-left: 4.28%;

							@include mq(md){
								width: 14.761%;
								height: 29.268%;
								margin: 3% 0 0 0;
							}

							.downloadBtnIconPath{
								fill: $basicColor;
								transition: fill .3s , transform .5s .3s;

								&.downloadBtnIconPathMove{
									transform: translate(0, -5%);
								}
							}
						}
					}

					.archiveLink{
						&:hover{
							cursor: pointer;
							background: $basicColor;
							#archiveLinkTxt{
								color: $basicWhiteColor;
							}
							#archiveLinkIcon{
								transform: rotate(360deg);
								.archiveLinkIconPath{
									fill: $basicWhiteColor;
								}
							}
						}
						#archiveLinkTxt{
							transition: color .3s;
						}
						#archiveLinkIcon{
							width: 9.571%;
							height: 38.01%;
							margin-left: 4.28%;
							transition: transform 1s .3s;

							@include mq(md){
								width: 19.919%;
								height: 51.012%;
								margin: 0;
							}

							.archiveLinkIconPath{
								fill: $basicColor;
								transform-origin: center;
								transition: fill .3s;
							}
						}
					}

					.rePlayBtn{
						&:hover{
							cursor: pointer;
							background: $basicColor;
							#rePlayBtnTxt{
								color: $basicWhiteColor;
							}
							#rePlayBtnIcon{
								transform: rotate(-360deg);
								.rePlayBtnPath{
									fill: $basicWhiteColor;
								}
							}
						}
						#rePlayBtnTxt{
							transition: color .3s;
						}
						#rePlayBtnIcon{
							width: 9.571%;
							height: 38.01%;
							margin-left: 4.28%;
							transform-origin: center;
							transition: transform 1s .3s;

							@include mq(md){
								width: 19.919%;
								height: 51.012%;
								margin: 0;
							}

							.rePlayBtnPath{
								fill: $basicColor;
								transition: fill .3s;
							}
						}
					}
				}
			}

			&.isShow{
				.animeImgArea{
					animation: animeImgCircle 10s infinite;
				}
			}
		}
	}
}